import Page from "../chi-siamo";
import { graphql } from "gatsby";

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allExpertsJson(sort: { fields: order }) {
      nodes {
        id
        linkedinUrl
        name
        role
        picture
      }
    }
  }
`;
